export default{
    "收益率": "Daromad stavkasi",
    "结束时间": "Tugash vaqti",
    "短期融资": "Qisqa muddatli investitsiyalar",
    "记录": "Yozuv",
    "转出本金": "Asosiy mablag'ni o'tkazish",
    "转出收益": "Foydani o'tkazish",
    "钱包余额": "Hamyon balansı",
    "天": "Kun",
    "请输入用户名": "Iltimos, foydalanuvchi nomini kiriting",
    "请输入密码": "Iltimos, parolni kiriting",
    "提交": "Yuborish",
    "立即开户": "Hozir ro'yxatdan o'tish",
    "联系客服": "Mijozlarga xizmat bilan bog'lanish",
    "联系客服2": "Mijozlarga xizmatga murojaat qiling",
    "用户名": "Foydalanuvchi nomi",
    "密码": "Parol",
    "确认密码": "Parolni tasdiqlash",
    "请确认密码": "Iltimos, parolni tasdiqlang",
    "邀请码": "Taklif kodi",
    "请输入邀请码": "Iltimos, taklif kodini kiriting",
    "真是姓名(可选)": "Haqiqiy ism (ixtiyoriy)",
    "身份证号码(可选)": "ID raqami (ixtiyoriy)",
    "已经有一个帐户": "Allaqachon akkauntingiz bormi",
    "版权所有©2024TS股份有限公司保留所有权利": "Copyright © 2024 TS Kompaniyasi. Barcha huquqlar himoyalangan",
    "此网站支持IPv6访问": "Ushbu sayt IPv6 kirishini qo'llab-quvvatlaydi",
    "在线用户": "Onlayn foydalanuvchilar",
    "快速入口": "Tez kirish",
    "支持全球USDT输入": "Global USDT kirishini qo'llab-quvvatlaydi",
    "关于我们": "Biz haqimizda",
    "帮助中心": "Yordam markazi",
    "市场": "Bozor",
    "产品趋势": "Mahsulotlar tendentsiyasi",
    "订单历史": "Buyurtmalar tarixi",
    "我的账户": "Mening hisobim",
    "持仓": "Pozitsiyalar",
    "今日开业": "Bugungi ochilish",
    "昨天收盘": "Kecha yopilishi",
    "最高": "Eng yuqori",
    "最低": "Eng past",
    "K线": "K-chiziq",
    "趋势": "Trend",
    "挂单": "Kutishdagi buyurtmalar",
    "数量": "Miqdor",
    "买涨价": "Narxni oshirish",
    "买跌价": "Narxni kamaytirish",
    "时间": "Vaqt",
    "去向": "Yo'nalish",
    "买涨": "Narxni oshirish",
    "买跌": "Narxni kamaytirish",
    "持仓详情": "Pozitsiyalar tafsilotlari",
    "结算时间": "Hisoblash vaqti",
    "购买时间": "Sotib olish vaqti",
    "盈利": "Foyda",
    "没有更多数据": "Boshqa ma'lumot yo'q",
    "购买确认": "Sotib olishni tasdiqlash",
    "名称": "Nom",
    "方向": "Yo'nalish",
    "当前价格": "Hozirgi narx",
    "账户余额": "Hisob balansı",
    "秒": "Sekund",
    "最小购买金额": "Minimal sotib olish miqdori",
    "收益": "Daromad",
    "取消": "Bekor qilish",
    "确定": "Tasdiqlash",
    "持仓中": "Pozitsiyalarda",
    "历史持仓": "Tarixiy pozitsiyalar",
    "利润/损失/时间": "Foyda/Zarar/Vaqt",
    "产品/金额": "Mahsulot/Miqdor",
    "加载中": "Yuklanmoqda",
    "充值": "Depozit",
    "提款": "Pul yechish",
    "财务详细信息": "Moliyaviy tafsilotlar",
    "实名认证": "Shaxsni tasdiqlash",
    "提款历史记录": "Pul yechish tarixi",
    "订单历史记录": "Buyurtmalar tarixi",
    "我的团队": "Mening jamoam",
    "邀请": "Taklif etish & Taklif qilish",
    "用户等级": "Foydalanuvchi darajasi",
    "杠杆作用": "Leverage",
    "修改密码": "Parolni o'zgartirish",
    "切换语言": "Til sozlamalari",
    "退出登录": "Tizimdan chiqish",
    "存入市场": "Bozorga depozit qilish",
    "充值数量": "Depozit miqdori",
    "登录密码": "Kirish paroli",
    "提款密码": "Pul yechish paroli",
    "首次设置时，请输入4位提款密码": "Birinchi marta sozlashda, 4 raqamli pul yechish parolini kiriting",
    "请重新输入4位数字密码": "Iltimos, 4 raqamli raqamli parolni qayta kiriting",
    "新密码": "Yangi parol",
    "请仔细检查您的信息": "Iltimos, ma'lumotlaringizni diqqat bilan tekshiring",
    "银行名称": "Bank nomi",
    "银行代码": "Bank kodi",
    "银行账户": "Bank hisobi",
    "持卡人": "Karta egasi",
    "银行分行": "Bank filiali",
    "提款数量": "Pul yechish miqdori",
    "请输入提款数量": "Iltimos, pul yechish miqdorini kiriting",
    "友情提示": "Do'stona eslatma",
    "印花税": "Bichuv solig'i",
    "如果你有任何问题，请": "Agar sizda savollar bo'lsa, iltimos",
    "时间": "Vaqt",
    "过滤": "Filtrlash",
    "开始": "Boshlash",
    "结束": "Tugash",
    "请选择时间": "Iltimos, vaqtni tanlang",
    "交易类型": "Savdo turi",
    "投资结算": "Investitsiya hisob-kitobi",
    "在线充值": "Onlayn depozit",
    "提款退款": "Pul yechish refundi",
    "投资支出": "Investitsiya xarajatlari",
    "提款申请": "Pul yechish arizasi",
    "提款成功": "Pul yechish muvaffaqiyatli",
    "重置": "Qayta tiklash",
    "实名制验证": "Haqiqiy ismni tasdiqlash",
    "匿名用户": "Anonim foydalanuvchi",
    "真实姓名": "To'liq ism",
    "请输入真实姓名": "Iltimos, to'liq ismingizni kiriting",
    "身份证号码": "ID raqami",
    "请输入身份证号码": "Iltimos, ID raqamini kiriting",
    "邀请二维码": "Taklif QR kodi",
    "复制邀请链接": "Taklif havolasini nusxalash",
    "复制成功": "Nusxalash muvaffaqiyatli",
    "下属人数": "Bo'ysundirilganlar soni",
    "团队规模": "Jamoa hajmi",
    "团队余额": "Jamoa balansı",
    "团队帐户更改": "Jamoa hisobidagi o'zgarish",
    "团队投资": "Jamoa investitsiyalari",
    "团队报告": "Jamoa hisobotlari",
    "状态": "Holat",
    "未解决": "Hal qilinmagan",
    "稳定的": "Barqaror",
    "概述": "Umumiy ko'rinish",
    "总余额": "Jami balans",
    "投资": "Investitsiya",
    "盈利能力": "Rentabellik",
    "存取款差异": "Depozit/yechish farqi",
    "总损益": "Jami foyda/zarar",
    "退出成功": "Chiqish muvaffaqiyatli",
    "请先完成实名认证": "Iltimos, avval haqiqiy ismni tasdiqlang",
    "绑定银行卡": "Bank kartasini bog'lash",
    "请输入4位数字密码以验证您的身份": "Iltimos, kimligingizni tasdiqlash uchun 4 raqamli parolni kiriting",
    "请绑定银行卡": "Iltimos, bank kartasini bog'lang",
    "收入": "Daromad",
    "支出": "Xarajat",
    "价格": "Narx",
    "此功能尚未打开": "Ushbu funksiya hali ochilmagan",
    "固定": "Doimiy",
    "操作": "Amal",
    "详情": "Tafsilotlar",
    "复制失败": "Nusxalash muvaffaqiyatsiz",
    "切换成功": "O'zgartirish muvaffaqiyatli",
    "网上借款": "Onlayn qarz olish",
    "公告": "E'lon",
    "银行卡": "Bank kartasi",
    "请选择银行": "Iltimos, bankni tanlang",
    "请填写充值金额": "Iltimos, depozit miqdorini kiriting",
    "提款地址": "Pul yechish manzili",
    "请输入提款地址": "Iltimos, pul yechish manzilini kiriting",
    "您还未绑定提款地址，请先绑定提款地址!": "Siz hali pul yechish manzilini bog'lamadingiz, iltimos, avval manzilni bog'lang!",
    "USDT地址": "USDT manzili",
    "应转USDT金额": "USDT ga o'zgartirilishi kerak bo'lgan miqdor",
    "客服": "Mijozlarga xizmat",
    "请先设置提款密码": "Iltimos, avval pul yechish parolini o'rnating",
    "余额": "Balans",
    "格式不正确": "Format noto'g'ri",
    "取款支出": "Pul yechish xarajatlari",
    "取款退回": "Pul yechish refundi",
    "充值成功": "Depozit muvaffaqiyatli",
    "上次交易": "Oxirgi savdo",
    "APP下载": "Ilovani yuklab olish",
    "佣金": "Komissiya",
    "账户余额R50000以下，1:1配比": "Hisob balans R50000 dan past, 1:1 nisbat",
    "账户余额R50001-R100000，1:2配比": "Hisob balans R50001-R100000, 1:2 nisbat",
    "账户余额R100001-R200000，1:3配比": "Hisob balans R100001-R200000, 1:3 nisbat",
    "账户余额R200001-R500000，1:4配比": "Hisob balans R200001-R500000, 1:4 nisbat",
    "账户余额R500000以上，1:5配比": "Hisob balans R500000 dan yuqori, 1:5 nisbat",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "Masalan, agar sizning hisobingizda R100000 bo'lsa, platforma sizning hisobingizga 1:2 nisbatida R200000 depozit qo'yadi, shunda sizning investitsiya asosiy kapitalingiz R300000 bo'ladi.",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣金": "45 kunlik shartnomani tugatgandan so'ng, siz platformaning moslashtirilgan asosiy kapitalini qaytarishda 10% foyda komissiyasini to'lashingiz kerak bo'ladi.",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "Agar hisob balansining 30% asosiy kapitali zarar qilsa, tizim shartnomani avtomatik tarzda bekor qiladi.",
    "说明": "Tavsif",
    "点击参与": "Ishtirok etish uchun bosing",
    "参与成功": "Muvaffaqiyatli ishtirok",
    "已参与": "Allaqachon ishtirok etilgan",
    "正面": "Old tomon",
    "反面": "Orqa tomon",
    "请上传证件": "Iltimos, hujjatlaringizni yuklang",
    "系统错误，请稍后尝试！": "Tizim xatosi, iltimos, keyinroq qayta urinib ko'ring!",
    "全民代理": "Barcha uchun vakolatli agent",
    "区域总代理—需要拥有多年品牌推广经验，以及管理能力，需经平台审核资质并缴纳50万美金的保证金,可享受代理团队总收益的2%": "Mintaqaviy umumiy agent: ko'p yillik brendni targ'ib qilish tajribasiga ega bo'lishi, boshqaruv qobiliyatiga ega bo'lishi kerak, platforma tomonidan malaka tekshiruvidan o'tishi va 500 000 dollar depozit to'lashi kerak, agentlar jamoasining umumiy daromadidan 2% olish huquqiga ega bo'ladi.",
    "一级代理人，享受下级收益的10%": "Birinchi darajali agent: Pastki darajadagi agentlarning daromadining 10% ni olish huquqiga ega.",
    "二级代理人，享受下级收益的5%": "Ikkinchi darajali agent: Pastki darajadagi agentlarning daromadining 5% ni olish huquqiga ega.",
    "三级代理人，享受下级收益的3%": "Uchinchi darajali agent: Pastki darajadagi agentlarning daromadining 3% ni olish huquqiga ega.",
    "投资币": "Investitsiya valyutasi",
    "累计理财收益": "Yig'ilgan daromadlar",
    "凭证": "Kvitasya",
    "请先完成实名认证": "Iltimos, avval haqiqiy ismni tasdiqlang",
    "请上传凭证": "Iltimos, kvitansiyani yuklang",
    "只有完成一个周期的订单后，才可以继续另外一个新的订单": "Bir tsikl buyurtmasi tugagandan so'nggina yangi buyurtma berish mumkin.",
    "转账到金融账户后，每天至少需要20个小时才能产生收入": "Moliyaviy hisobga pul o'tkazgandan so'ng, daromad olish uchun kamida 20 soat kutish kerak.",
    "财务管理收入将在财务管理期结束后提取，财务管理金额在此期间不能提取": "Moliyaviy boshqaruv daromadi boshqaruv muddati tugagandan so'ng yechib olinishi mumkin, lekin asosiy mablag' bu davrda yechib olinmaydi.",
    "未认证": "Tasdiqlanmagan",
    "待审核": "Tasdiqlash kutilyapti",
    "已认证": "Tasdiqlangan",
    "已驳回": "Rad etilgan",
    "提示": "Eslatma",
    "登录": "Kirish",
    "身份证/护照正面": "ID karta / Pasport old tomoni",
    "身份证/护照反面": "ID karta / Pasport orqa tomoni",
    "体验金": "Tajriba Oltin",
    "金额": "Miqdor",
    "未领取": "Olinmagan",
    "领取中": "Qabul qilinmoqda",
    "已过期": "Muddati o'tgan",
    "领取时间": "Qabul qilish vaqti",
    "过期时间": "Muddati tugash vaqti",
    "领取": "Qabul qilish"
}
