import { createRouter, createWebHistory } from 'vue-router'
// var token = localStorage.getItem('token')? localStorage.getItem('token') : ''
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* 首页 */ '../views/Home/home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* 登录 */ '../views/Login/login.vue'),
    // redirect: token ? '/home' : ''
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* 注册 */ '../views/Login/register.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import(/* 商品详情 */ '../views/Home/detail.vue'),
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import(/* 历史记录 */ '../views/Record/record.vue'),
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* 联系客服 */ '../views/Service/service.vue'),
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import(/* 个人中心 */ '../views/Mine/mine.vue'),
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import(/* 充值 */ '../views/Mine/modules/recharge.vue'),
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import(/* 密码 */ '../views/Mine/modules/password.vue'),
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import(/* 提款 */ '../views/Mine/modules/withdraw.vue'),
  },
  {
    path: '/binding',
    name: 'Binding',
    component: () => import(/* 银行卡 */ '../views/Mine/modules/binding.vue'),
  },
  {
    path: '/fundDetail',
    name: 'FundDetail',
    component: () => import(/* 资金详情 */ '../views/Mine/modules/fundDetail.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* 实名 */ '../views/Mine/modules/auth.vue'),
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* 邀请 */ '../views/Mine/modules/invite.vue'),
  },
  {
    path: '/level',
    name: 'Level',
    component: () => import(/* 会员等级 */ '../views/Mine/modules/level.vue'),
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* 我的团队 */ '../views/Mine/modules/team.vue'),
  },
  {
    path: '/teamRecord',
    name: 'TeamRecord',
    component: () => import(/* 团队投资，报告，成员记录 */ '../views/Mine/modules/teamRecord.vue'),
  },
  {
    path: '/rich',
    name: 'Rich',
    component: () => import(/* 关于我们 */ '../views/Home/rich.vue'),
  },
  {
    path: '/leverage',
    name: 'Leverage',
    component: () => import(/* 杠杆 */ '../views/Mine/modules/leverage.vue'),
  },
  {
    path: '/teamDetail',
    name: 'TeamDetail',
    component: () => import(/* 团队详情 */ '../views/Mine/modules/teamDetail.vue'),
  },
  {
    path: '/security',
    name: 'security',
    component: () => import(/* 安全 */ '../views/Security/security.vue'),
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import(/* 安全 */ '../views/Mine/modules/address.vue'),
  },
  {
    path: '/excharge',
    name: 'excharge',
    component: () => import(/* 安全 */ '../views/Mine/modules/excharge.vue'),
  },
  {
    path: '/downLoad',
    name: 'downLoad',
    component: () => import(/* 下载 */ '../views/Mine/modules/download.vue'),
  },
  {
    path: '/gold',
    name: 'gold',
    component: () => import(/* 体验金 */ '../views/Mine/modules/gold.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
