<template>
  <router-view />
</template>
<script>
import { onMounted, ref, onUnmounted } from "vue";
import { useI18n } from 'vue-i18n';
// import WebSocketService  from "./api/websocket";
export default {
  setup() {
    const { t , locale } = useI18n()
    onMounted(()=> {
      var lang = localStorage.getItem('locale')
      if (!lang) {
        locale.value = 'ru-ru'
        localStorage.setItem('locale','ru-ru')
      }
    });

    return {
      
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
  background-color: #333333;
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media screen and (min-width: 780px) {
    #app {
      width: 375px;
      margin: 0 auto;
    }
}

</style>
